import React, { FC } from 'react'
import { Diagnosis as FhirDiagnosis } from '../../../../types/FHIRTypes/Diagnosis'
import { handleCodeableConcept } from '../../../../utils/helpers'

interface FhirDiagnosisProps {
    diagnosis?: FhirDiagnosis[]
}

const Diagnosis: React.FC<FhirDiagnosisProps> = ({ diagnosis }) => {
    if (diagnosis) {
        return (
            <>

                {diagnosis.map((diagnosis, index) => (
                    <div key={`diagnosis-${index}`}>{`${handleCodeableConcept(diagnosis.type)}: ${handleCodeableConcept(diagnosis.diagnosisCodeableConcept)}`}
                        {diagnosis.onAdmission ? `, ${handleCodeableConcept(diagnosis.onAdmission)}` : ''}</div>
                ))}
            </>
        )
    }
    return null
}
export default Diagnosis