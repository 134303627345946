import React, { FC } from 'react'

interface CareTeamProviderProps {
  sequence?: number | string;
  providerReference?: string;
  role?: string;
}

const CareTeamProvider: FC<CareTeamProviderProps> = ({ sequence, providerReference, role }) => {
  return (
    <div className="mb-3">
    {role && providerReference && `${role}: ${providerReference}`}
  </div>
  )

}

export default CareTeamProvider
