import React from 'react'
import { SupportingInfo } from '../../../../types/FHIRTypes/ExplanationOfBenefit'
import { handleCodeableConcept } from '../../../../utils/helpers'
import Date from '../../DataTypes/Date'
import { getDataAbsentReason } from '../../../../utils/fhirUtils/getDataAbsentReason'
import Quantity from '../../DataTypes/Quantity'
import OrganizationReference from '../OrganizationReference'
import Coding from '../../DataTypes/Coding'
import StringDisplay from '../../DataTypes/String'
import { CodeableConcept as FHIRCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Quantity as FHIRQuantity } from '../../../../types/FHIRTypes/Quantity'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Extension from '../../DataTypes/Extension'

interface EobSupportingInfoProps {
    supportingInfo?: SupportingInfo
}

const renderCodeableConcept = (code?: FHIRCodeableConcept) => {
    return code?.coding ? <CodeableConcept data={code} /> : <Extension nestedExtension={code} />
}

const renderStringValue = (code?: FHIRCodeableConcept, value?: string) => {
    return value ? <StringDisplay data={value} /> : <Extension nestedExtension={code} />
}

const renderQuantity = (code?: FHIRCodeableConcept, valueQuantity?: FHIRQuantity) => {
    return valueQuantity ? <Quantity data={valueQuantity} /> : <Extension nestedExtension={code} />
}

const renderDate = (code?: FHIRCodeableConcept, timingDate?: string) => {
    return timingDate ? <Date date={timingDate} /> : <Extension nestedExtension={code} />
}

const renderValueAndTime = (code?: FHIRCodeableConcept, valueString?: string, timingDate?: string, valueQuantity?: FHIRQuantity) => {
    if (code?.extension) return <Extension nestedExtension={code} />

    return (
        <div className='d-flex'>
            <span className='mr-2'>
                {timingDate && <Date date={timingDate} />}
            </span>
            <span className='mr-2'>
                <StringDisplay data={valueString} />
            </span>
            <span className='mr-2'>
                <CodeableConcept data={code} />
            </span>
            <span className='mr-2'>
                <Quantity data={valueQuantity} />
            </span>
            + Months
        </div>
    )
}

const renderSupportingInfoValue = (category: string, supportingInfo: SupportingInfo) => {
    switch (category) {
        case 'additionalbodysite':
        case 'admtype':
        case 'brandgenericindicator':
        case 'compoundcode':
        case 'drg':
        case 'discharge-status':
        case 'dawcode':
        case 'missingtoothnumber':
        case 'pointoforigin':
        case 'rxorigincode':
        case 'typeofbill':
            return renderCodeableConcept(supportingInfo.code)
        case 'admissionperiod':
        case 'clmrecvddate':
            return renderDate(supportingInfo.code, supportingInfo.timingDate)
        case 'ambulancetransportreason':
        case 'roundtrippurpose':
        case 'stretcherpurpose':
            return supportingInfo.reason ? <Coding data={supportingInfo.reason} dataExtension={supportingInfo._reason}/> : renderCodeableConcept(supportingInfo.code)
        case 'dropofflocation':
        case 'medicalrecordnumber':
        case 'patientaccountnumber':
        case 'pickuplocation':
            return renderStringValue(supportingInfo.code, supportingInfo.valueString)
        case 'dayssupply':
        case 'patientweight':
        case 'refillnum':
        case 'refillsAuthorized':
        case 'transportationdistance':
            return renderQuantity(supportingInfo.code, supportingInfo.valueQuantity)
        case 'orthodontics':
        case 'prosthesis':
            return renderValueAndTime(supportingInfo.code, supportingInfo.valueString, supportingInfo.timingDate, supportingInfo.valueQuantity)
        case 'servicefacility':
            return supportingInfo.valueReference ? <OrganizationReference organization={supportingInfo.valueReference} /> : <>{getDataAbsentReason([])}</>
        default:
            return <>{getDataAbsentReason([])}</>
    }
}


const EobSupportingInfo: React.FC<EobSupportingInfoProps> = ({ supportingInfo }) => {
    if (!supportingInfo) return null

    const category = supportingInfo.category?.coding?.[0].code || ''
    const value = renderSupportingInfoValue(category, supportingInfo)

    return (
        <div className='d-flex'>
            <span className='mr-2'>
                <CodeableConcept data={supportingInfo.category} dataExtension={supportingInfo._category} />
            </span>
            {value}
        </div>
    )
}

export default EobSupportingInfo