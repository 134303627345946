import React, { FC } from 'react'
import { HumanName as Name } from '../../../../types/FHIRTypes/HumanName'
import { getHumanName } from '../../../../utils/fhirUtils/getHumanName'

interface HumanNameProps {
    name?: Name;
    showUse?: boolean;
}

const HumanName: FC<HumanNameProps> = ({ name, showUse }) => {
    if (!name) return null

    return (
        <span>
            {getHumanName(name, showUse)}
        </span>
    )
}

export default HumanName