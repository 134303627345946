import React, { FC } from 'react'
import { CarePlanBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner } from 'react-bootstrap'
import CarePlanActivity from '../CarePlanActivity'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Narrative from '../../../DataTypes/Narrative'
import Identifier from '../../../DataTypes/Identifier'
import NarrativeStatus from '../../../DisplayComponents/NarrativeStatus'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import Period from '../../../DataTypes/Period'
import PatientReference from '../../../DisplayComponents/PatientReference'
import Simple from '../../../DisplayComponents/Simple'
import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import DateTime from '../../../DataTypes/DateTime'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'
import CodeableConcept from '../../../DataTypes/CodeableConcept'

interface Props {
  carePlanDetailId: string
  carePlanData: CarePlanBundle
  isFetching: boolean
}

const config: FieldConfig = {
  text: { label: 'Care Plan Summary', visibility: 'always' },
  subject: { label: 'Subject', visibility: 'always' },
  category: { label: 'Category', visibility: 'always' },
  intent: { label: 'Intent', visibility: 'always' },
  status: { label: 'Status', visibility: 'always' },
  title: { label: 'Title', visibility: 'conditional' },
  description: { label: 'Description', visibility: 'conditional' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  period: { label: 'Period', visibility: 'conditional' },
  created: { label: 'Created', visibility: 'conditional' },
  author: { label: 'Author', visibility: 'conditional' },
  contributor: { label: 'Contributor', visibility: 'conditional' },
  careTeam: { label: 'Care Team', visibility: 'conditional' },
  addresses: { label: 'Addresses', visibility: 'conditional' },
  supportingInfo: { label: 'Supporting Info', visibility: 'conditional' },
  goal: { label: 'Goal', visibility: 'conditional' },
  activity: { label: 'Activity', visibility: 'conditional' },
  basedOn: { label: 'Based On', visibility: 'never' },
  replaces: { label: 'Replaces', visibility: 'never' },
  partOf: { label: 'Part Of', visibility: 'never' },
  note: { label: 'Note', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const CarePlanDetail: FC<Props> = ({ carePlanDetailId, carePlanData, isFetching }) => {
  const carePlanEntry = carePlanData?.entry?.find((carePlan: any) => carePlan.resource.id === carePlanDetailId)
  const resource = carePlanEntry?.resource

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as='span'
              animation='border'
              role='status'
              aria-hidden='true'
            />
            <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          resource ? (
            <div>

              <Row>
                <ShowField field='text' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><Narrative text={resource.text} /></dd>
                    <dd><NarrativeStatus status={resource.text?.status} /></dd>
                  </Col >
                </ShowField >
                <ShowField field='subject' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.subject.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><PatientReference patient={resource.subject} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='category' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.category.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='intent' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.intent.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><Code data={resource.intent} dataExtension={resource._intent}/></dd>
                  </Col>
                </ShowField>
                <ShowField field='status' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.status.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><Code data={resource.status} dataExtension={resource._status}/></dd>
                  </Col>
                </ShowField>
                <ShowField field='title' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.title.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><StringDisplay data={resource.title ?? ''} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='description' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.description.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><StringDisplay data={resource.description ?? ''} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='encounter' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.encounter.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><Reference data={resource.encounter} dataExtension={resource._encounter} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='period' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.period.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.period ? <Period period={resource.period} /> : ''}</dd>
                  </Col>
                </ShowField>
                <ShowField field='created' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.created.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><DateTime dateTime={resource.created ?? ''} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='author' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.author.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><Reference data={resource.author} dataExtension={resource._author} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='contributor' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.contributor.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.contributor?.map((contributor, index) => (
                          <Reference key={`careplan-contributor-${index}`} data={contributor} dataExtension={resource._contributor} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='careTeam' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.careTeam.label}</dt>
                  </Col>
                  <Col sm={9} className='my-2'>
                    {
                      resource.careTeam?.map((careTeam, index) => (
                        <Reference key={`careplan-careteam-${index}`} data={careTeam} dataExtension={resource._careTeam} />
                      ))
                    }
                  </Col>
                </ShowField>
                <ShowField field='addresses' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.addresses.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.addresses?.map((address, index) => (
                          <Reference key={`careplan-addresses-${index}`} data={address} dataExtension={resource._addresses} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='supportingInfo' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.supportingInfo.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.supportingInfo?.map((supportingInfo, index) => (
                          <Reference key={`careplan-supportinginfo-${index}`} data={supportingInfo} dataExtension={resource._supportingInfo} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='goal' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.goal.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.goal?.map((goal, index) => (
                          <Reference key={`careplan-goal-${index}`} data={goal} dataExtension={resource._goal} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='activity' config={config} resource={resource}>
                  <Col sm={12}>
                    <CarePlanActivity patientCarePlan={resource} />
                  </Col>
                </ShowField>
                <ShowField field='basedOn' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.basedOn.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.basedOn?.map((basedOn, index) => (
                          <Reference key={`careplan-basedon-${index}`} data={basedOn} dataExtension={resource._basedOn} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='replaces' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.replaces.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.replaces?.map((replaces, index) => (
                          <Reference key={`careplan-replaces-${index}`} data={replaces} dataExtension={resource._replaces} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='partOf' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.partOf.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>
                      {
                        resource.partOf?.map((partOf, index) => (
                          <Reference key={`careplan-partof-${index}`} data={partOf} dataExtension={resource._partOf} />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='note' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.note.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{resource.note?.map((note) => note.text).join(', ') ?? ''}</dd>
                  </Col>
                </ShowField>
              </Row >

              <div className='footer'>
                <hr />

                <h6>FHIR Resource Metadata</h6>
                <Row>
                  <ShowField field='resourceType' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.resourceType.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='id' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.id.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><StringDisplay data={resource.id} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='meta' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.meta.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><MetaLastUpdated meta={resource.meta} /></dd>
                    </Col >
                  </ShowField >
                  <ShowField field='profile' config={config} resource={resource.meta}>
                    <Col sm={3}>
                      <dt>{config.profile.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>{<Profile profile={resource.meta?.profile} />}</dd>
                    </Col>
                  </ShowField>
                  <ShowField field='language' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.language.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='implicitRules' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.implicitRules.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><URI uri={resource.implicitRules} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='identifier' config={config} resource={resource}>
                    <Col sm={3}>
                      <dt>{config.identifier.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <Identifier identifiers={resource.identifier} />
                    </Col >
                  </ShowField >
                </Row >
              </div >
              <Row>
                <Col sm={12}>
                  <ProvenanceDetail resourceName='CarePlan' resourceId={carePlanDetailId} />
                </Col>
              </Row>

            </div >
          ) : (
            <div className='text-center'>
              No Care Plan found for this member.
            </div>
          )
        }
      </dl >
    </>
  )
}

export default CarePlanDetail