import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatDollar } from '../../../../../utils/helpers'
import { AdjudicationCodeEnum } from '../../../../../types/AdjudicationCodeEnum'
import humanizeString from 'humanize-string'
import { Card } from 'react-bootstrap'
import { Adjudication } from '../../../../../types/FHIRTypes/ExplanationOfBenefit'
import Money from '../../../DataTypes/Money'
import AdjudicationAmount from '../../../DisplayComponents/AdjudicationAmount'
import AdjudicationHeader from '../../../DisplayComponents/AdjudicationHeader'

interface AdjudicationProps {
    adjudications?: Adjudication[];
}

const AdjudicationItem: FC<AdjudicationProps> = ({ adjudications }) => {
    if (!adjudications || adjudications.length === 0) return null

    return (
        <Card className='mt-3'>
            <Card.Body>
                Adjudications:    
                    <AdjudicationHeader adjudications={adjudications} />
                    <AdjudicationAmount adjudications={adjudications} />
            </Card.Body>
        </Card>
    )

}
export default AdjudicationItem