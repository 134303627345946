import React, { FC } from 'react'
import { Payee as FhirPayee } from '../../../../types/FHIRTypes/Payee'
import { handleReference } from '../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../utils/helpers'

interface FhirPayeeProps {
    payee?: FhirPayee;
}

const Payee: React.FC<FhirPayeeProps> = ({ payee }) => {
    if (payee) {
        const caption = handleCodeableConcept(payee.type)
        const field = handleReference(payee.party)
        return <span>{`${caption}: ${field}`}</span>
    }
    return null
}
export default Payee