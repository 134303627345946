import React from 'react'
import { Extension } from '../../../../types/FHIRTypes/Extension'
interface OrganizationDescriptionProps {
    extension?: Extension[]
}
const OrganizationDescription: React.FC<OrganizationDescriptionProps> = ({ extension }) => {
    const orgDescription = extension?.find(
        (ext) => ext.url === 'http://hl7.org/fhir/us/davinci-pdex-plan-net/StructureDefinition/org-description'
    )?.valueString

    return (
        <div className="organization-description">
            <span>{orgDescription}</span>
        </div>
    )
}

export default OrganizationDescription
