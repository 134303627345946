// component to display medication dosage instructions

import React, { FC } from 'react'
import { Dosage as FHIRDosage} from '../../../../types/FHIRTypes/MedicationRequest'

interface DosageProps {
  dosageInstruction: FHIRDosage;
}

const Dosage: FC<DosageProps> = ({ dosageInstruction }) => {
    if (!dosageInstruction) {
        return null
    }

    return (
        <div>
            {dosageInstruction.text}
        </div>
    )
}

export default Dosage