import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import { Location } from '../../../../types/FHIRTypes/Location'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'
import ReferencePopover from '../../../ReferencePopover'
import Address from '../../DataTypes/Address'
import ShowField from '../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../types/FieldConfig'
import ContactPoint from '../../DataTypes/ContactPoint'
import CodeableConcept from '../../DataTypes/CodeableConcept'

interface LocationReferenceProps {
    location?: FHIRReference
}

const locationFieldConfig: FieldConfig = {
    address: { label: 'Address', visibility: 'always' },
    telecom: { label: 'Contact Information', visibility: 'always' },
    type: { label: 'Location Type', visibility: 'conditional' },
    physicalType: { label: 'Physical Type', visibility: 'conditional' },
    value: { label: 'Value', visibility: 'always' }
}

const LocationReference: React.FC<LocationReferenceProps> = ({ location }) => {
    if (!location) return null

    const reference = location?.reference?.split('/')
    const locationId = reference?.[1] || ''

    const {
        data: locationData,
        refetch: getLocation,
        isFetching: fetchingLocation,
        isLoading: loadingLocation
    }: UseQueryResult<Location, Error> = useFhirResourceById(locationId, 'Location')

    useEffect(() => {
        if (location) {
            getLocation()
        }
    }, [location])

    if (fetchingLocation || loadingLocation) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    return (
        <div className='d-flex'>
            {
                locationData?.name
                    ? (
                        <div className='d-flex align-items-center'>
                            {locationData.name}
                            <ReferencePopover
                                title={locationData.name}
                            >
                                <ShowField field='address' config={locationFieldConfig} resource={locationData}>
                                    {locationData?.address && <Address address={locationData.address} />}
                                </ShowField>
                                <ShowField field='telecom' config={locationFieldConfig} resource={locationData}>
                                    {locationData?.telecom && locationData.telecom.map((telecom, index) => (
                                        <ContactPoint key={`location-telecom-${index}`} contactPoint={telecom} />
                                    ))}
                                </ShowField>
                                <ShowField field='type' config={locationFieldConfig} resource={locationData}>
                                    <CodeableConcept data={locationData.type} dataExtension={locationData._type} />
                                </ShowField>
                                <ShowField field='physicalType' config={locationFieldConfig} resource={locationData}>
                                    <CodeableConcept data={locationData.physicalType} dataExtension={locationData._physicalType} />
                                </ShowField>
                                <ShowField field='value' config={locationFieldConfig} resource={locationData}>
                                    <Reference data={location} />
                                </ShowField>
                            </ReferencePopover>
                        </div>
                    )
                    : (
                        <div className='dark-gray-text'>
                            <Reference data={location} />
                        </div>
                    )
            }
        </div>
    )
}

export default LocationReference