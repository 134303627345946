import React, { FC } from 'react'
import ObservationDetail from './ObservationDetail'
import ObservationList from './ObservationList'
import { ObservationBundle } from '../../../../types/FHIRTypes/Bundle'

interface Props {
  observationData: ObservationBundle;
  patientData: any;
  observationDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const Observations: FC<Props> = ({ observationData, patientData, observationDetailId, page, isFetching, handleChangePage }: Props) => {

  return (
    <div>
      {
        observationDetailId && page === 'Observation' ? (
          <ObservationDetail
            observationData={observationData}
            patientData={patientData}
            detailId={observationDetailId} />
        ) : <ObservationList
          observationData={observationData}
          isFetching={isFetching}
          handleChangePage={handleChangePage} />
      }
    </div>
  )
}

export default Observations