export const providerResources = [{
    fhirResource: 'Organization',
    label: 'Organizations',
    filter: 'Name'
},
{
    fhirResource: 'Practitioner',
    label: 'Practitioners',
    filter: 'Name'
}, {
    fhirResource: 'Network',
    label: 'Networks'
}
]