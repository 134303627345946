// This enum contains the codes from system http://terminology.hl7.org/CodeSystem/coverage-class but does not currently include codes defined by HLX in http://data.healthlx.com/CodeSystem/coverage-class.
// The enum defines the display values as described in the FHIR documentation. 

export enum CoverageClassCodeEnum {
    group = 'Group',
    subgroup = 'SubGroup',
    plan = 'Plan',
    subplan = 'SubPlan',
    class = 'Class',
    subclass = 'SubClass',
    sequence = 'Sequence',
    rxbin = 'RX BIN',
    rxpcn = 'RX PCN',
    rxid = 'RX Id',
    rxgroup = 'RX Group',
}