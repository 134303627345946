import React, { FC } from 'react'
import { Coding as FHIRCoding } from '../../../../types/FHIRTypes/Coding'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import { isCoding } from '../../../../typeGuards/fhirTypes'
import Extension from '../Extension'

interface CodingProps {
  data?: FHIRCoding | CodeableConcept
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Coding: FC<CodingProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }

    if (dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    if (isCoding(data)) {
        return <>{data.display}</>
    }
    return <Extension nestedExtension={data} />
}

export default Coding