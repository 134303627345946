import React, { FC } from 'react'
import { CoverageClassCodeEnum } from '../../../../types/CoverageClassCodeEnum'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import humanizeString from 'humanize-string'

interface Props {
  coverageClass: {
    type: CodeableConcept
    value: string
    name?: string
  }[]
}

const mandatoryTypes = ['group', 'plan']
const typeOrder = ['group', 'subgroup', 'plan', 'subplan', 'class', 'subclass', 'sequence', 'rxbin', 'rxpcn', 'rxid', 'rxgroup']

const processCoverageClasses = (coverageClass: Props['coverageClass']) => {
  const classes = [...coverageClass]

  mandatoryTypes.forEach((type) => {
    if (!classes.some((c) => c.type.coding?.[0]?.code === type)) {
      classes.push({
        type: { coding: [{ code: type }] },
        value: 'N/A'
      })
    }
  })

  const sortedCoverageClass = classes.sort((a, b) => {
    const aType = a.type.coding?.[0].code
    const bType = b.type.coding?.[0]?.code
    const aIndex = typeOrder.indexOf(aType ?? '')
    const bIndex = typeOrder.indexOf(bType ?? '')

    if (aIndex === -1 && bIndex === -1) return 0
    if (aIndex === -1) return 1
    if (bIndex === -1) return -1
    return aIndex - bIndex
  })

  return sortedCoverageClass
}

const getTypeDisplay = (type: string) => {
  return CoverageClassCodeEnum[type as keyof typeof CoverageClassCodeEnum] ?? humanizeString(type)
}

const CoverageClass: FC<Props> = ({ coverageClass }) => {
  if (!coverageClass || coverageClass.length === 0) return null

  const processedCoverageClass = processCoverageClasses(coverageClass)

  return (
    <div>
      {processedCoverageClass.map((c, index) => {
        const typeCode = c.type.coding?.[0]?.code
        const typeDisplay = getTypeDisplay(typeCode ?? '')
        return (
          <div key={`class-${index}`}>
            {typeDisplay}: {c.value} {c.name ? c.name : ''}
          </div>
        )
      })}
    </div>
  )
}

export default CoverageClass