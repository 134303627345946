import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatDateInTimeZone } from '../../../../../utils/helpers'
import PaginationButtons from '../../../../PaginationButtons'
import { ImmunizationBundle } from '../../../../../types/FHIRTypes/Bundle'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import DateTimeOrString from '../../../DataTypes/DateTimeOrString'
import Code from '../../../DataTypes/Code'


interface Props {
  immunizationData: ImmunizationBundle;
  patientData: any;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const ImmunizationList: FC<Props> = ({ patientData, immunizationData, isFetching, handleChangePage }: Props) => {
  // const immunization = immunizationData && immunizationData.entry ? immunizationData.entry.find((i: any) => i.resource.id === detailId) : ''
  const immunizations = immunizationData && immunizationData.entry ? immunizationData.entry.map((i: any) => i.resource) : []

  const getImmunizationStatus = (item: any) => {
    return item?.status || ''
  }

  const getImmunizationOccurrence = (item: any) => {
    return item?.occurrenceDateTime ? formatDateInTimeZone(item.occurrenceDateTime, 'MM/dd/yyyy', 'UTC') : ''
  }
  const nextLink = immunizationData && immunizationData?.link.find((l: any) => l.relation === 'next')
  const previousLink = immunizationData && immunizationData?.link.find((l: any) => l.relation === 'previous')

  return (
    <>
      {
        immunizationData && immunizationData.total > 0 && (
          <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
            {immunizationData.total > 0 &&
              <div className='mb-2'>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </div>
            }
            {`${immunizationData.total.toLocaleString()} record(s) found`}
          </div>
        )
      }

      <Table size='sm' hover>
        <thead>
          <tr>
            <th className='d-table-cell'>Vaccine Code</th>
            <th className='d-none d-lg-table-cell'>Date Administered</th>
            <th className='d-none d-lg-table-cell'>Status</th>
            <th className='d-none d-lg-table-cell'>Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Member Immunizations...</span>
                </td>
              </tr>
            ) : immunizationData?.total === 0 ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='text-center'>
                  No Immunizations found for this member.
                </td>
              </tr>
            ) : (
              <>
                {
                  immunizations.map((immunization: any) => (
                    <tr key={immunization.id}>
                      <td className='d-table-cell'><Link to={`/patient/Immunization/${immunization.id}`}><CodeableConcept data={immunization.vaccineCode} dataExtension={immunization._vaccineCode} /></Link></td>
                      <td className='d-none d-lg-table-cell'>
                        <DateTimeOrString dateTime={immunization?.occurrenceDateTime} string={immunization?.occurrenceString} />
                      </td>
                      <td className='d-none d-lg-table-cell'><Code data={immunization.status} dataExtension={immunization._status} /></td>
                      <td className='d-none d-lg-table-cell'><Link to={`/patient/Immunization/${immunization.id}`}>Detail</Link></td>
                    </tr>
                  ))
                }
              </>
            )

          }
        </tbody>
        <tfoot>
          {immunizationData && immunizationData.total > 0 &&
            <tr>
              <td scope="row" colSpan={5}>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </td>
            </tr>
          }
        </tfoot>

      </Table>
    </>
  )
}

export default ImmunizationList