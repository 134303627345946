import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'
import { Organization } from '../../../../types/FHIRTypes/Organization'
import ReferencePopover from '../../../ReferencePopover'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../../DisplayComponents/FieldVisibilityWrapper'

interface OrganizationReferenceProps {
    organization?: FHIRReference
}

const organizationFieldConfig: FieldConfig = {
    value: { label: 'Value', visibility: 'always' },
}

const OrganizationReference: React.FC<OrganizationReferenceProps> = ({ organization }) => {
    if (!organization) return null

    const reference = organization?.reference?.split('/')
    const organizationId = reference?.[1] || ''

    const {
        data: organizationData,
        refetch: getOrganization,
        isFetching: fetchingOrganization,
        isLoading: loadingOrganization
    }: UseQueryResult<Organization, Error> = useFhirResourceById(organizationId, 'Organization')

    useEffect(() => {
        if (organization) {
            getOrganization()
        }
    }, [organization])

    if (fetchingOrganization || loadingOrganization) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    return (
        <div className='d-flex'>
            {organizationData?.name ? (
                <div className='d-flex align-items-center'>
                    {organizationData.name}
                    <ReferencePopover
                        title={organizationData.name}
                    >
                        <ShowField field='value' config={organizationFieldConfig} resource={organizationData}>
                            {organization.reference}
                        </ShowField>
                    </ReferencePopover>
                </div>
            ) : <Reference data={organization} />}
        </div>
    )
}

export default OrganizationReference