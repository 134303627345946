export enum DataAbsentReasonCodeEnum {
  unknown = 'Unknown',
  askedUnknown = 'Asked But Unknown',
  tempUnknown = 'Temporarily Unknown',
  notAsked = 'Not Asked',
  askedDeclined = 'Asked But Declined',
  masked = 'Masked',
  notApplicable = 'Not Applicable',
  unsupported = 'Unsupported',
  asText = 'As Text',
  error = 'Error',
  notANumber = 'Not a Number (NaN)',
  negativeInfinity = 'Negative Infinity (NINF)',
  positiveInfinity = 'Positive Infinity (PINF)',
  notPerformed = 'Not Performed',
  notPermitted = 'Not Permitted'
}