import React, { FC } from 'react'
import { Related } from '../../../../types/FHIRTypes/Related'
import { handleCodeableConcept } from '../../../../utils/helpers'

interface RelatedClaimProps {
    relatedClaim?: Related[];
}

const RelatedClaim: React.FC<RelatedClaimProps> = ({ relatedClaim }) => {
    if (relatedClaim) {
        <>
            {relatedClaim.map((related, index) => (
                <div key={`related-${index}`}>{`${handleCodeableConcept(related.relationship)}: ${related.reference}`}</div>
            ))}
        </>
    }
    return null
}

export default RelatedClaim