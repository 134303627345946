import React, { FC, useEffect } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
// import { formatDateWithFormat } from '../../../../utils/helpers'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import { ObservationBundle } from '../../../../../types/FHIRTypes/Bundle'
import Smoking from '../Smoking'
import { Observation, ObservationComponent } from '../../../../../types/FHIRTypes/Observation'
import { useHistory } from 'react-router-dom'
import DataAbsentReason from '../../../DataTypes/DataAbsentReason'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import ObservationValue from '../../../DisplayComponents/ObservationValue'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import ResourceType from '../../../DisplayComponents/ResourceType'
import StringDisplay from '../../../DataTypes/String'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import Profile from '../../../DisplayComponents/Profile'
import URI from '../../../DataTypes/URI'
import Identifier from '../../../DataTypes/Identifier'
import ProvenanceDetail from '../../Provenance'
import Reference from '../../../DataTypes/Reference'
import PeriodOrDateTime from '../../../DataTypes/PeriodOrDateTime'
import Instant from '../../../DataTypes/Instant'
import Narrative from '../../../DataTypes/Narrative'
import PatientReference from '../../../DisplayComponents/PatientReference'
import PractitionerReference from '../../../DisplayComponents/PractitionerReference'

interface Props {
  detailId: string | undefined;
  observationData: ObservationBundle;
  patientData: any;
}

const config: FieldConfig = {
  status: { label: 'Status', visibility: 'always' },
  category: { label: 'Category', visibility: 'always' },
  code: { label: 'Type', visibility: 'always' },
  subject: { label: 'Patient', visibility: 'always' },
  focus: { label: 'Focus', visibility: 'conditional' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  effectiveDateTime: { label: 'Effective', visibility: 'conditional' },
  issued: { label: 'Issued', visibility: 'conditional' },
  performer: { label: 'Performer', visibility: 'conditional' },
  value: { label: 'Value', visibility: 'always' },
  dataAbsentReason: { label: 'Data Absent Reason', visibility: 'conditional' },
  interpretation: { label: 'Interpretation', visibility: 'conditional' },
  bodySite: { label: 'Body Site', visibility: 'conditional' },
  method: { label: 'Method', visibility: 'conditional' },
  text: { label: 'Summary', visibility: 'conditional' },

  //metadata
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
  basedOn: { label: 'Based On', visibility: 'conditional' },
  partOf: { label: 'Part Of', visibility: 'conditional' }
}

const ObservationDetail: FC<Props> = ({ detailId, observationData, patientData }: Props) => {
  const [observationResource, setObservationResource] = React.useState<Observation | undefined>(undefined)

  const history = useHistory()
  useEffect(() => {
    if (!observationData) return

    const observationEntry = observationData.entry?.find((observation: any) => observation.resource.id === detailId)

    if (observationEntry) {
      setObservationResource(observationEntry.resource)
    } else {
      history.push('/404')
    }
  }, [observationData])


  if (observationResource && observationResource.code && handleCodeableConcept(observationResource.code) === 'Tobacco smoking status') {
    return <Smoking resource={observationResource} patientData={patientData} />
  }

  const handleObservationEffectiveDate = (observation: Observation | undefined) => {
    if (!observation) return ''

    if (observation.effectiveDateTime) {
      return new Date(observation.effectiveDateTime).toLocaleDateString()
    }

    if (observation.effectivePeriod) {
      const startDate = observation.effectivePeriod.start || ''
      const endDate = observation.effectivePeriod.end || ''
      return endDate
        ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`
        : new Date(startDate).toLocaleDateString()
    }

    return ''
  }

  const handleObservationValue = (observation: Observation | ObservationComponent | undefined) => {
    if (!observation) return ''

    const { valueQuantity, valueCodeableConcept, valueString, valueBoolean, valueInteger, valueRange, valueRatio, valueSampledData, valueTime, valueDateTime, valuePeriod } = observation

    if (valueQuantity) {
      return `${valueQuantity.value} ${valueQuantity.unit}`
    }

    if (valueCodeableConcept) {
      return <CodeableConcept data={valueCodeableConcept} />
    }

    if (valueString || observation._valueString) {
      return valueString || <DataAbsentReason dataExtension={observation._valueString} />
    }

    if (valueBoolean !== undefined) {
      return valueBoolean ? 'Yes' : 'No'
    }

    if (valueInteger !== undefined) {
      return valueInteger.toString()
    }

    if (valueRange) {
      return `${valueRange.low.value} ${valueRange.low?.unit} - ${valueRange.high.value} ${valueRange.high?.unit}`
    }

    if (valueRatio) {
      return `${valueRatio.numerator.value} ${valueRatio.numerator.unit} : ${valueRatio.denominator.value} ${valueRatio.denominator.unit}`
    }

    if (valueSampledData) {
      return `${valueSampledData.origin?.value} ${valueSampledData.origin?.unit} - ${valueSampledData.period}`
    }

    if (valueTime) {
      return valueTime
    }

    if (valueDateTime) {
      return new Date(valueDateTime).toLocaleDateString()
    }

    if (valuePeriod) {
      const startDate = valuePeriod.start || ''
      const endDate = valuePeriod.end || ''
      return endDate ? `${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}` : `${new Date(startDate).toLocaleDateString()}`
    }

    return ''
  }

  return (
    <>
      <dl className='dataContainer'>
        <Row>
          <ShowField field='status' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.status.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><Code data={observationResource?.status} dataExtension={observationResource?._status} /></dd>
            </Col>
          </ShowField>
          <ShowField field='category' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.category.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><CodeableConcept data={observationResource?.category} dataExtension={observationResource?._category} /></dd>
            </Col>
          </ShowField>
          <ShowField field='code' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.code.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><CodeableConcept data={observationResource?.code} dataExtension={observationResource?._code} /></dd>
            </Col>
          </ShowField>
          <ShowField field='subject' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.subject.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><PatientReference patient={observationResource?.subject} /></dd>
            </Col>
          </ShowField>


          <ShowField field="focus" config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.focus.label}</dt>
            </Col>
            <Col sm={9}>
              <dd>
                {observationResource?.focus?.map((focus, index) => (
                  <div key={index}>
                    <Reference data={focus} dataExtension={observationResource?._focus} />
                  </div>
                ))}
              </dd>
            </Col>
          </ShowField>
          <ShowField field='encounter' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.encounter.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><Reference data={observationResource?.encounter} dataExtension={observationResource?._encounter} /></dd>
            </Col>
          </ShowField>

          <ShowField field='effectiveDateTime' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.effectiveDateTime.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><PeriodOrDateTime dateTime={observationResource?.effectiveDateTime} /></dd>
            </Col>
          </ShowField>
          <ShowField field='issued' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.issued.label}</dt>
            </Col>
            <Col sm={9}>
              <dd>
                {observationResource?.issued && (
                  <Instant instant={new Date(observationResource.issued).toLocaleDateString()} />
                )}
              </dd>
            </Col>
          </ShowField>

          <ShowField field='performer' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.performer.label}</dt>
            </Col>
            <Col sm={9}>
              <dd>
                {observationResource?.performer?.map((performer, index) => (
                  <div key={index}>
                    <PractitionerReference practitioner={performer} />
                  </div>
                ))}
              </dd>
            </Col>
          </ShowField>

          <ShowField field='value' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>Value</dt>
            </Col>
            <Col sm={9}>
              <dd><ObservationValue observation={observationResource} /></dd>
            </Col>
          </ShowField>
          <ShowField field='dataAbsentReason' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.dataAbsentReason.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><CodeableConcept data={observationResource?.dataAbsentReason} dataExtension={observationResource?._dataAbsentReason} /></dd>
            </Col>
          </ShowField>
          <ShowField field='interpretation' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.interpretation.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><CodeableConcept data={observationResource?.interpretation} dataExtension={observationResource?._interpretation} /></dd>
            </Col>
          </ShowField>
          <ShowField field='bodySite' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.bodySite.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><CodeableConcept data={observationResource?.bodySite} dataExtension={observationResource?._bodySite} /></dd>
            </Col>
          </ShowField>
          <ShowField field='method' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.method.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><CodeableConcept data={observationResource?.method} dataExtension={observationResource?._method} /></dd>
            </Col>
          </ShowField>
          <ShowField field='text' config={config} resource={observationResource}>
            <Col sm={3}>
              <dt>{config.text.label}</dt>
            </Col>
            <Col sm={9}>
              <dd><Narrative text={observationResource?.text} /></dd>
            </Col>
          </ShowField>
          <Col sm={12} className='footer'>
            <hr />

            <h6>FHIR Resource Metadata</h6>
            <Row>
              <ShowField field='resourceType' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.resourceType.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{<ResourceType resourceType={observationResource?.resourceType} />}</dd>
                </Col>
              </ShowField>
              <ShowField field='id' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.id.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><StringDisplay data={observationResource?.id} /></dd>
                </Col>
              </ShowField>
              <ShowField field='meta' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.meta.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    <MetaLastUpdated meta={observationResource?.meta} />
                  </dd>
                </Col>
              </ShowField>
              <ShowField field='profile' config={config} resource={observationResource?.meta}>
                <Col sm={3}>
                  <dt>{config.profile.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{<Profile profile={observationResource?.meta?.profile} />}</dd>
                </Col>
              </ShowField>
              <ShowField field='language' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.language.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><Code data={observationResource?.language} dataExtension={observationResource?._language} /></dd>
                </Col>
              </ShowField>
              <ShowField field='implicitRules' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.implicitRules.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><URI uri={observationResource?.implicitRules} /></dd>
                </Col>
              </ShowField>

              <ShowField field='identifier' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.identifier.label}</dt>
                </Col>
                <Col sm={9}>
                  <Identifier identifiers={observationResource?.identifier} />
                </Col>
              </ShowField>
              <ShowField field='basedOn' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.basedOn.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {observationResource?.basedOn?.map((basedOn, index) => (
                      <div key={index}>
                        <Reference data={basedOn} dataExtension={observationResource?._basedOn} />
                      </div>
                    ))}
                  </dd>
                </Col>
              </ShowField>
              <ShowField field='partOf' config={config} resource={observationResource}>
                <Col sm={3}>
                  <dt>{config.partOf.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {observationResource?.partOf?.map((part, index) => (
                      <div key={index}>
                        <Reference data={part} dataExtension={observationResource?._partOf} />
                      </div>
                    ))}
                  </dd>
                </Col>
              </ShowField>
            </Row>
          </Col>
          <Col sm={12}>
            <ProvenanceDetail resourceName='Observation' resourceId={detailId} />
          </Col>
        </Row>
      </dl >
    </>
  )
}

export default ObservationDetail