import React, { FC } from 'react'

interface SimpleProps {
    simple: string | boolean | number | null | undefined 
}


const Simple:FC<SimpleProps> = ({simple}) => {
  const getSimpleDisplay = (): string => String(simple ?? '')
  return (
    <div>{getSimpleDisplay()}</div>
  )
}

export default Simple