import React, { FC } from 'react'

interface DateTimeProps {
    dateTime?: string;
}

const DateTime: FC<DateTimeProps> = ({ dateTime }) => {
    if (dateTime) {
        return <span>{dateTime}</span>
    }
    return null
}

export default DateTime