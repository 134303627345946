import { DataAbsentReasonCodeEnum } from '../../types/DataAbsentReasonCodeEnum'
import { Extension } from '../../types/FHIRTypes/Extension'

export const getDataAbsentReason = (extensions: Extension | Extension[]): string | null => {
  const extensionsArray = Array.isArray(extensions) ? extensions : [extensions]
  const dataAbsentExtension = extensionsArray.find(
    (ext) => ext.url === 'http://hl7.org/fhir/StructureDefinition/data-absent-reason'
  )
  return dataAbsentExtension?.valueCode
    ? DataAbsentReasonCodeEnum[dataAbsentExtension.valueCode as keyof typeof DataAbsentReasonCodeEnum] ||
    dataAbsentExtension.valueCode
    : null
}