import React, { FC } from 'react'
import { Meta } from '../../../../types/FHIRTypes/Meta'
import { formatDateWithFormat } from '../../../../utils/helpers'


interface MetaProps {
    meta: Meta | undefined
}

const MetaLastUpdated: FC<MetaProps> = ({ meta }) => {
    const getMetaDisplay = (): string => {
        if (meta && meta.lastUpdated) {
            return meta?.lastUpdated
        }
        return ''
    }
    return (
        <div>
            {getMetaDisplay()}
        </div>
    )
}

export default MetaLastUpdated