import React, { FC } from 'react'
import { formatDollar } from '../../../../utils/helpers'

interface MoneyProps {
    value?: number
    currency?: string
}

const Money: FC<MoneyProps> = ({ value, currency }) => {
  return (
    <span>{formatDollar(value ?? 0)}</span>
  )
}

export default Money