import { isAfter, isWithinInterval, parseISO } from 'date-fns'
import { capitalizeFirstLetter, handleCodeableConcept } from '../helpers'
import { Coverage } from '../../types/FHIRTypes/Coverage'
import humanizeString from 'humanize-string'

export function calculatedStatus(resource: Coverage): string {
  if (!resource.period) {
    return 'N/A'
  } else {
    const { start, end } = resource.period
    const today = new Date()

    if (start && !end) {
      return isAfter(today, parseISO(start)) ? 'Active' : 'N/A'
    } else if (start && end) {
      return isWithinInterval(today, { start: parseISO(start), end: parseISO(end) }) ? 'Active' : 'Inactive'
    } else {
      return 'N/A'
    }
  }
}

export function buildCoverageMemberID(resource: Coverage, isSingleTenantServer: any): string {
  if (isSingleTenantServer) {
    return getBeneficiaryReference(resource)
  }

  return getIdentifierValue(resource)
}

function getBeneficiaryReference(resource: Coverage): string {
  if (resource?.beneficiary?.reference) {
    const parts = resource.beneficiary.reference.split('/')
    return parts.length > 1 ? parts[1] : ''
  }
  return ''
}

function getIdentifierValue(resource: Coverage): string {
  if (resource?.identifier?.[0]?.value) {
    return resource.identifier[0].value
  }
  return 'N/A'
}

export const sortCoveragesByStartDate = (coverages: Coverage[]): Coverage[] => {
  return coverages.sort((a, b) => {
    const startDateA = a.period?.start ? new Date(a.period.start).getTime() : 0
    const startDateB = b.period?.start ? new Date(b.period.start).getTime() : 0
    return startDateB - startDateA
  })
}