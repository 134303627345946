import React from 'react'
import { PatientContact as Contact } from '../../../../types/FHIRTypes/Patient'
import HumanName from '../../DataTypes/HumanName'
import { handleCodeableConcept } from '../../../../utils/helpers'
import ContactPoint from '../../DataTypes/ContactPoint'
import { Col, Row } from 'react-bootstrap'
import Address from '../../DataTypes/Address'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface PatientContactProps {
    contact: Contact
}

const PatientContact: React.FC<PatientContactProps> = ({ contact }) => {

    return (
        <div className='border-rounded p-2'>
            <Row>
                {
                    contact.name && (
                        <>
                            <Col sm={3}>
                                Name
                            </Col>
                            <Col sm={9}>
                                <HumanName name={contact.name} />
                            </Col>
                        </>
                    )
                }
                {
                    contact.relationship && (
                        <>
                            <Col sm={3}>
                                Relationship
                            </Col>
                            <Col sm={9}>
                                {
                                    contact.relationship.map((relationship, index) => (
                                        <div key={`contact-relationship-${index}`}>{getContentOrFallback(handleCodeableConcept(relationship))}</div>
                                    ))
                                }
                            </Col>
                        </>
                    )
                }
                {
                    contact.telecom && (
                        <>
                            <Col sm={3}>
                                Contact
                            </Col>
                            <Col sm={9}>
                                {contact.telecom.map((telecom, index) => (
                                    <ContactPoint key={`contact-telecom-${index}`} contactPoint={telecom} />
                                ))}
                            </Col>
                        </>
                    )
                }
                {
                    contact.address && (
                        <>
                            <Col sm={3}>
                                Address
                            </Col>
                            <Col sm={9}>
                                <Address address={contact.address} />
                            </Col>
                        </>
                    )
                }
                {
                    contact.gender && (
                        <>
                            <Col sm={3}>
                                Gender
                            </Col>
                            <Col sm={9}>
                                {getContentOrFallback(contact.gender)}
                            </Col>
                        </>
                    )
                }
                {
                    contact.organization && (
                        <>
                            <Col sm={3}>
                                Organization
                            </Col>
                            <Col sm={9}>
                                {getContentOrFallback(contact.organization.reference)}
                            </Col>
                        </>
                    )
                }
                {
                    contact.period && (
                        <>
                            <Col sm={3}>
                                Period
                            </Col>
                            <Col sm={9}>
                                {contact.period.map((period, index) => (
                                    <div key={`contact-period-${index}`}>{period.start || 'N/A'} - {period.end || 'N/A'}</div>
                                ))}
                            </Col>
                        </>
                    )
                }
            </Row>
        </div>
    )
}

export default PatientContact