import React from 'react'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ProcedureBundle } from '../../../../../types/FHIRTypes/Bundle'
import { ProcedureEntry } from '../../../../../types/FHIRTypes/Entry'
import { Procedure } from '../../../../../types/FHIRTypes/Procedure'
import PaginationButtons from '../../../../PaginationButtons'
import Code from '../../../DataTypes/Code'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import PeriodOrDateTime from '../../../DataTypes/PeriodOrDateTime'

interface ProcedureListProps {
    procedureData: ProcedureBundle;
    isFetching: boolean;
    handleChangePage: (url: string) => void;
}

const ProcedureList: React.FC<ProcedureListProps> = ({ procedureData, isFetching, handleChangePage }) => {
    const procedures: Procedure[] = procedureData && procedureData.entry ? procedureData.entry.map((procedure: ProcedureEntry) => procedure.resource) : []

    const nextLink = procedureData && procedureData.link.find((l: any) => l.relation === 'next')
    const previousLink = procedureData && procedureData.link.find((l: any) => l.relation === 'previous')


    const getProcedurePerformedDate = (procedure: Procedure) => {
        if (procedure.performedDateTime) {
            return new Date(procedure.performedDateTime).toLocaleDateString()
        }

        if (procedure.performedPeriod) {
            const startDate = procedure.performedPeriod.start ? new Date(procedure.performedPeriod.start).toLocaleDateString() : ''
            const endDate = procedure.performedPeriod.end ? new Date(procedure.performedPeriod.end).toLocaleDateString() : ''
            return endDate ? `${startDate} - ${endDate}` : startDate
        }

        if (procedure.performedString) {
            return procedure.performedString
        }

        if (procedure.performedAge) {
            return `${procedure.performedAge}`
        }

        if (procedure.performedRange) {
            return `${procedure.performedRange.low.value} - ${procedure.performedRange.high.value}`
        }

        return ''
    }

    return (
        <>
            {procedures.length > 0 && (
                <div className="mb-2">
                    <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
                        {`${procedureData.total.toLocaleString()} record(s) found`}
                    </div>
                    <PaginationButtons
                        backDisabled={!previousLink}
                        nextDisabled={!nextLink}
                        onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                        onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                    />
                </div >
            )}

            <Table size='sm' hover>
                <thead>
                    <tr>
                        <th className='d-table-cell'> Procedure Code</th>
                        <th className='d-none d-lg-table-cell'>Status</th>
                        <th className='d-none d-lg-table-cell'>When Performed</th>
                        <th className='d-none d-lg-table-cell'>Detail</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isFetching ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='d-flex'>
                                    Loading Member Procedures...
                                </td>
                            </tr>
                        ) : procedures?.length === 0 ? (
                            <tr>
                                <td colSpan={4} rowSpan={15} className='text-center'>
                                    No Procedures found for this member.
                                </td>
                            </tr>
                        ) : (
                            <>
                                {procedures.map((procedure) => (
                                    <tr key={procedure.id}>
                                        <td className='d-table-cell'><Link to={`/patient/Procedure/${procedure.id}`}> <CodeableConcept data={procedure?.code} dataExtension={procedure._code} /></Link></td>
                                        <td className='d-none d-lg-table-cell'>
                                            <Code data={procedure.status} dataExtension={procedure._status} />
                                        </td>
                                        <td className='d-none d-lg-table-cell'><PeriodOrDateTime period={procedure.performedPeriod} dateTime={procedure.performedDateTime} /></td>
                                        <td className='d-none d-lg-table-cell'><Link to={`/patient/Procedure/${procedure.id}`}>Detail</Link></td>
                                    </tr>
                                ))}
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                    {procedures.length > 0 &&
                        <tr>
                            <td scope="row" colSpan={5}>
                                <PaginationButtons
                                    backDisabled={!previousLink}
                                    nextDisabled={!nextLink}
                                    onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                                    onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                                />
                            </td>
                        </tr>
                    }
                </tfoot>
            </Table>
        </>
    )
}

export default ProcedureList