import { useQuery, UseQueryResult } from 'react-query'
import request from 'superagent'
import { getApiServer } from '../../utils/domain'

type UserAuthenticated = boolean

export default function useUserAuthenticated(): UseQueryResult<UserAuthenticated> {
    return useQuery('user-authenticated', async () => {
        const response = await request.get(`${getApiServer()}/auth/authenticated`).set('Accept', 'application/json').withCredentials()
        const { status, expireTime } = response.body

        sessionStorage.setItem('sessionTimeOut', expireTime)

        return status
    }, {
        retry: 0,
        enabled: false
    })
}