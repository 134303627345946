import React, { FC, useState } from 'react'
import { useEffect } from 'react'
import { Alert, Breadcrumb, Button, Col, Container, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import useAccount from '../../../hooks/admin/useAccount'
import { calculateAge, formatDate, formatDateWithFormat } from '../../../utils/helpers'
import isAuthenticated from '../../../routes/PrivateRoute'
import useSaveAccount from '../../../hooks/admin/useSaveAccount'
import useSendForgotEmail from '../../../hooks/admin/useSendForgotEmail'
import PermissionGate from '../../PermissionGate'
import useUserWithRole from '../../../hooks/authentication/useUserWithRole'

interface RouteMatchParams {
  accountId: string;
}

const Account: FC = () => {
  const [alertState, setAlertState] = useState({
    show: false,
    variant: 'info',
    message: ''
  })
  const { accountId } = useParams<RouteMatchParams>()
  const { data: account, refetch: refetchAccount, isLoading: accountLoading, isSuccess } = useAccount(accountId)
  const updateAccount = useSaveAccount(accountId)
  const { user } = useUserWithRole()

  useEffect(() => {
    refetchAccount()
  }, [])

  useEffect(() => {

    if (updateAccount.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully updated account'
      })
    }

    if (updateAccount.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Unable to update account: '
      })
    }
  }, [updateAccount.isSuccess, updateAccount.isError])

  const handleUpdateAccount = () => {
    updateAccount.mutate({
      enabled: !account.enabled
    })
  }

  const forgotLogin = useSendForgotEmail()
  const handlePasswordReset = () => {
    forgotLogin.mutate({
      email: account.email,
      type: 'Password',
      memberId: account?.attributes ? account?.attributes.memberId[0] : account?.memberId,
      id: accountId
    })
  }

  useEffect(() => {
    if (forgotLogin.isSuccess) {
      setAlertState({
        show: true,
        variant: 'success',
        message: 'Successfully requested user to reset password.'
      })
    }

    if (forgotLogin.isError) {
      setAlertState({
        show: true,
        variant: 'danger',
        message: 'Unable to send Password Reset: '
      })
    }
  }, [forgotLogin.isSuccess, forgotLogin.isError])

  return (
    <main>
      <PermissionGate
        user={user}
        role='admin'
      >
        <Container>
          {/* <Breadcrumb>
        <Breadcrumb.Item href='/admin'>Admin Home</Breadcrumb.Item>
        <Breadcrumb.Item href='/accounts'>Member Accounts</Breadcrumb.Item>
        <Breadcrumb.Item active>Member Account</Breadcrumb.Item>
      </Breadcrumb> */}
          <Row>
            <Col sm={6}>
              <h2 className='headerText'>Member Account</h2>
            </Col>
            <Col sm={12}>
              <Alert show={alertState.show} variant={alertState.variant} dismissible onClose={() => setAlertState((ps) => ({ ...ps, show: false }))}>
                {alertState.message}
                {
                  updateAccount.isError && (<div dangerouslySetInnerHTML={{ __html: updateAccount.error.response.text }} />)
                }
              </Alert>
              {
                accountLoading && (
                  <div>
                    <Spinner
                      as='span'
                      animation='border'
                      role='status'
                      size='sm'
                      aria-hidden='true'
                    />
                    Account Loading..
                  </div>
                )
              }
              {
                isSuccess && (
                  <section className='dataContainer'>
                    <dl>
                      <Row>
                        <Col sm={3}>
                          <dt>Username</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {
                              account?.username
                            }
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Email</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {account?.email}
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Account ID</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {account?.memberId ? account?.memberId : ''}
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Proxy</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {account?.isProxy ? 'Yes' : ''}
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Proxy Name</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {account?.proxyFirstName || ''} {account?.proxyLastName || ''}
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Last Login</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            {account?.lastLogin ? formatDate(account?.lastLogin) : ''}
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Reset Password</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            <Row>
                              <Col sm={5}>
                                <Button className='custom' onClick={handlePasswordReset} disabled={forgotLogin.isLoading}>
                                  {
                                    forgotLogin.isLoading ? (
                                      <Spinner
                                        as='span'
                                        animation='border'
                                        role='status'
                                        size='sm'
                                        aria-hidden='true'
                                      />
                                    ) : 'Send Email'
                                  }
                                </Button>
                              </Col>
                              <Col sm={7}>
                                Will send a password reset email to the member.
                              </Col>
                            </Row>
                          </dd>
                        </Col>
                        <Col sm={3}>
                          <dt>Account Status</dt>
                        </Col>
                        <Col sm={9}>
                          <dd>
                            <Row>
                              <Col sm={1}>
                                {account?.enabled ? 'Active' : 'Locked'}
                              </Col>
                              <Col sm={4}>
                                <Button
                                  variant={account?.enabled ? 'danger' : 'success'}
                                  className='ml-5'
                                  onClick={handleUpdateAccount}
                                  disabled={updateAccount.isLoading}
                                >
                                  {
                                    updateAccount.isLoading ? (
                                      <Spinner
                                        as='span'
                                        animation='border'
                                        role='status'
                                        size='sm'
                                        aria-hidden='true'
                                      />
                                    ) : account?.enabled ? 'Lock Account' : 'Unlock Account'
                                  }
                                </Button>
                              </Col>
                              <Col sm={7}>
                                {
                                  account?.enabled
                                    ? 'Locking an account means that the member will not be able to log into the portal, and that third party apps who have been given access will not be able to connect.'
                                    : '"Unlock" means that the member can log into the portal and third party apps can connect. The member may still need to re-set their password.'
                                }
                              </Col>
                            </Row>
                          </dd>
                        </Col>
                      </Row>
                    </dl>
                  </section>
                )
              }
            </Col>
          </Row>
        </Container>
      </PermissionGate>
    </main>
  )
}

export default isAuthenticated(Account)