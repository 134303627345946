import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import Loader from '../../components/Loader'
import useUserAuthenticated from '../../hooks/authentication/useUserAuthenticated'
import { useGlobalState } from '../../hooks/store/useGlobalState'

const isAuthenticated = (ComposedComponent: React.ComponentType<any>): React.ComponentType<any> => {
  const Authenticated = (props: any) => {
    const { data: authenticated, refetch: checkIfAuthenticated, isError: authenticatedError } = useUserAuthenticated()
    const { isStale, refetch: refetchUser, status, removeUser } = useUserWithRole()
    const { setSessionTimeOut } = useGlobalState()

    const checkIfUserIsAuthenticated = () => {
      const sessionTimeOut = sessionStorage.getItem('sessionTimeOut')

      if (sessionTimeOut) {
        const currentTime = new Date().getTime()
        const timeOut = new Date(sessionTimeOut).getTime()

        if (currentTime > timeOut) {
          setSessionTimeOut(true)
        }
      }

      checkIfAuthenticated()
    }

    useEffect(() => {
      checkIfUserIsAuthenticated()

      window.addEventListener('focus', checkIfUserIsAuthenticated)

      return () => {
        window.removeEventListener('focus', checkIfUserIsAuthenticated)
      }
    }, [])

    useEffect(() => {
      if (authenticated && isStale && !authenticatedError) {
        refetchUser()
      }
    }, [authenticated, isStale, authenticatedError])

    useEffect(() => {
      if (authenticatedError) {
        removeUser()
      }
    }, [authenticatedError])

    if (status === 'success') {
      return <ComposedComponent {...props} />
    }

    const message = status === 'loading' ? 'Loading the current user\'s information' : 'Fetching the current user\'s information'
    return <Loader message={message} />
  }

  return withRouter(Authenticated)
}

export default isAuthenticated