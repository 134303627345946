import React, { FC } from 'react'
import { MedicationRequest } from '../../../../types/FHIRTypes/MedicationRequest'
import { handleCodeableConcept, handleReference } from '../../../../utils/helpers'
interface MedicationProps {
    medication: MedicationRequest | undefined;
}


const Medication: FC<MedicationProps> = ({ medication }) => {
    const getMedicationDisplay = (): string => {
        const codeableConceptDisplay = handleCodeableConcept(medication?.medicationCodeableConcept)
        const referenceDisplay = handleReference(medication?.medicationCodeableReference)

        return codeableConceptDisplay || referenceDisplay || ''
    }

    return (
        <div>
            {getMedicationDisplay()}
        </div>
    )
}

export default Medication