import React, { FC } from 'react'
import { Observation, ObservationComponent } from '../../../../types/FHIRTypes/Observation'
import { handleCodeableConcept } from '../../../../utils/helpers'
import Quantity from '../../DataTypes/Quantity'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Period from '../../DataTypes/Period'
import StringDisplay from '../../DataTypes/String'
import TimeDisplay from '../../DataTypes/Time'
import Boolean from '../../DataTypes/Boolean'
import DataAbsentReason from '../../DataTypes/DataAbsentReason'


interface ObservationValueProps {
    observation: Observation | ObservationComponent | undefined
}


const ObservationValue: FC<ObservationValueProps> = ({ observation }) => {
    if (!observation) return null

    const {
        valueQuantity,
        valueCodeableConcept,
        valueString,
        valueBoolean,
        valueInteger,
        valueTime,
        valueDateTime,
        valuePeriod,
    } = observation


    if (valueQuantity) {
        return <Quantity data={valueQuantity} dataExtension={observation._valueQuantity} />
    }

    if (valueCodeableConcept) {
        return <CodeableConcept data={valueCodeableConcept} dataExtension={observation._valueCodeableConcept} />
    }

    if (valueString || observation._valueString) {
        return <StringDisplay data={valueString} dataExtension={observation._valueString} />
    }

    if (valueBoolean !== undefined) {
        return <Boolean data={valueBoolean} dataExtension={observation._valueBoolean} />
    }

    if (valueInteger !== undefined) {
        return <StringDisplay data={valueInteger.toString()} dataExtension={observation._valueInteger} />
    }

    if (valueTime) {
        return <TimeDisplay time={valueTime}/>
    }

    if (valueDateTime) {
        return <>{new Date(valueDateTime).toLocaleDateString()}</>
    }

    if (valuePeriod) {
        return <Period period={valuePeriod} />
    }

    return null
}

export default ObservationValue
