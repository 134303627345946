import React, { FC } from 'react'

interface TimeProps {
    time?: string
}

const TimeDisplay: FC<TimeProps> = ({ time }) => {
    if (time) {
        return <div>{time}</div>
    }

    return null
}
export default TimeDisplay