import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import { AddItem } from '../../../../../types/FHIRTypes/ExplanationOfBenefit'

interface AddItemProps {
    addItems?: AddItem[];
}

const EobAddItem: FC<AddItemProps> = ({ addItems }) => (
    <>
        {addItems?.map((item, index) => (
            <Card key={`eob-add-item-${index}`} className="mt-3 w-100">
                <Card.Header>
                    <strong>Additional Item {index + 1}</strong>
                </Card.Header>
                <Card.Body>
                    {item.detail?.map((detail: any, detailIndex: number) => (
                        <div key={`eob-add-item-detail-${detailIndex}`}>
                            <strong>Detail {detailIndex + 1}:</strong>
                            <div>
                                <strong>Product/Service:</strong> {handleCodeableConcept(detail.productOrService)}
                            </div>
                            {detail.subDetail?.map((subDetail: any, subDetailIndex: number) => (
                                <div key={`eob-add-item-sub-detail-${subDetailIndex}`} className="ml-3">
                                    <strong>Sub-detail {subDetailIndex + 1}:</strong>
                                    <div>
                                        <strong>Product/Service:</strong> {handleCodeableConcept(subDetail.productOrService)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </Card.Body>
            </Card>
        ))}
    </>
)

export default EobAddItem
