import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import { CareTeamBundle } from '../../../../../types/FHIRTypes/Bundle'
// Display Components ---
import Period from '../../../DataTypes/Period'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Narrative from '../../../DataTypes/Narrative'
import Identifier from '../../../DataTypes/Identifier'
import ContactPoint from '../../../DataTypes/ContactPoint'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'

interface Props {
  careTeamDetailId: string
  careTeamData: CareTeamBundle
  isFetching: boolean
}

const config: FieldConfig = {
  subject: { label: 'Patient', visibility: 'always' },
  status: { label: 'Care Team Status', visibility: 'always' },
  category: { label: 'Category', visibility: 'conditional' },
  encounter: { label: 'Encounter', visibility: 'conditional' },
  period: { label: 'Period', visibility: 'conditional' },
  participant: { label: 'Care Team Members', visibility: 'always' },
  telecom: { label: 'Care Team Contact', visibility: 'conditional' },
  text: { label: 'Care Team Summary', visibility: 'conditional' },
  name: { label: 'Name', visibility: 'never' },
  reasonCode: { label: 'Reason', visibility: 'never' },
  reasonReference: { label: 'Reason Reference', visibility: 'never' },
  managingOrganization: { label: 'Managing Organization', visibility: 'never' },
  note: { label: 'Note', visibility: 'never' },
  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}

const CareTeamDetail: FC<Props> = ({ careTeamDetailId, careTeamData, isFetching }) => {

  const careTeamEntry = careTeamData?.entry?.find(
    (careTeam: any) => careTeam.resource.id === careTeamDetailId
  )


  const resource = careTeamEntry?.resource
  return (
    <>
      {isFetching && (
        <div className='d-flex dataContainer'>
          <Spinner
            as='span'
            animation='border'
            role='status'
            aria-hidden='true'
          />
          <span style={{ marginLeft: '10px' }}>Loading Care Team Data...</span>
        </div>
      )}
      <dl className='dataContainer'>
        {resource ? (
          <div>

            <Row>
              <ShowField field='subject' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.subject.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><PatientReference patient={resource.subject} /></dd>
                </Col>
              </ShowField>
              <ShowField field='status' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.status.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><Code data={resource.status} dataExtension={resource._status} /></dd>
                </Col>
              </ShowField>
              <ShowField field='category' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.category.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><CodeableConcept data={resource.category} dataExtension={resource._category} /></dd>
                </Col>
              </ShowField>
              <ShowField field='encounter' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.encounter.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    <Reference data={resource.encounter} dataExtension={resource._encounter} />
                  </dd>
                </Col >
              </ShowField >
              <ShowField field='period' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.period.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{resource.period ? <Period period={resource.period} /> : ''}</dd>
                </Col>
              </ShowField>
              <ShowField field='participant' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.participant.label}</dt>
                </Col>
                <Col sm={9}>
                  {resource.participant?.map((participant: any, index: number) => {
                  {/* check if the role data is absent. always show the role, but hide the member if the role is absent */}
                    const roleDataAbsent = participant.role?.some((role: any) => {
                      return role.extension?.some((ext: any) => ext.url === 'http://hl7.org/fhir/StructureDefinition/data-absent-reason')
                    })
                    return (
                      <div key={`participant-${index}`}>
                        <span>
                          {roleDataAbsent ? (
                            <CodeableConcept data={participant.role} dataExtension={participant._role} />
                          ) : (
                            <span>
                              <CodeableConcept data={participant.role} dataExtension={participant._role} />: <Reference data={participant.member} dataExtension={participant._member} />
                            </span>
                          )}
                        </span>
                      </div>
                    )
                  })}
                </Col>
              </ShowField>
              <ShowField field='telecom' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.telecom.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {resource.telecom?.map((telecom, index) => (
                      <ContactPoint key={`contact-telecom-${index}`} contactPoint={telecom} />
                    ))}
                  </dd>
                </Col>
              </ShowField>
              <ShowField field='text' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.text.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><Narrative text={resource.text} /></dd>
                </Col>
              </ShowField>
              <ShowField field='name' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.name.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{resource.name}</dd>
                </Col>
              </ShowField>
              <ShowField field='reasonCode' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.reasonCode.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd><CodeableConcept data={resource.reasonCode} dataExtension={resource._reasonCode} /></dd>
                </Col>
              </ShowField>
              <ShowField field='reasonReference' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.reasonReference.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      resource.reasonReference?.map((reasonReference, index) => (
                        <Reference key={`careteam-reasonreference-${index}`} data={reasonReference} dataExtension={resource._reasonReference} />
                      ))
                    }
                  </dd >
                </Col >
              </ShowField >
              <ShowField field='managingOrganization' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.managingOrganization.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>
                    {
                      resource.managingOrganization?.map((managingOrganization, index) => (
                        <Reference key={`careteam-managingorganization-${index}`} data={managingOrganization} dataExtension={resource._managingOrganization} />
                      ))
                    }
                  </dd >
                </Col >
              </ShowField >
              <ShowField field='note' config={config} resource={resource}>
                <Col sm={3}>
                  <dt>{config.note.label}</dt>
                </Col>
                <Col sm={9}>
                  <dd>{resource.note?.map((note: any) => note.text).join(', ')}</dd>
                </Col>
              </ShowField>
            </Row >

            <div className='footer'>
              <hr />

              <h6>FHIR Resource Metadata</h6>
              <Row>
                <ShowField field='resourceType' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.resourceType.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                  </Col>
                </ShowField>
                <ShowField field='id' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.id.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><StringDisplay data={resource.id} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='meta' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.meta.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><MetaLastUpdated meta={resource.meta} /></dd>
                  </Col >
                </ShowField >
                <ShowField field='profile' config={config} resource={resource.meta}>
                  <Col sm={3}>
                    <dt>{config.profile.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd>{<Profile profile={resource.meta?.profile} />}</dd>
                  </Col>
                </ShowField>
                <ShowField field='implicitRules' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.implicitRules.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><URI uri={resource.implicitRules} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='language' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.language.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <dd><Code data={resource.language} dataExtension={resource._language} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier' config={config} resource={resource}>
                  <Col sm={3}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={9}>
                    <Identifier identifiers={resource.identifier} />
                  </Col>
                </ShowField>
              </Row >
            </div >
            <Row>
              <Col sm={12}>
                <ProvenanceDetail resourceName='CareTeam' resourceId={careTeamDetailId} />
              </Col>
            </Row>
          </div >
        ) : (
          <div className='text-center'>
            No Care Teams found for this member.
          </div>
        )}
      </dl >
    </>
  )
}

export default CareTeamDetail