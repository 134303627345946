import React, { FC, useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import useLogoutUser from '../../hooks/authentication/useLogoutUser'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'

import client_logo from '../../assets/customizations/logos/client_banner.svg'

import portalBanner from '../../assets/customizations/logos/member_portal_banner.svg'
import { appConfig } from '../../assets/customizations/config'


import '../../App.scss'

const Header: FC = () => {
  const [activeTab, setActiveTab] = useState('')
  const [showSecondaryNav, setShowSecondaryNav] = useState(true)
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
      case '/':
      case '/admin':
      case '/login':
        setActiveTab('home')
        break
      case '/help':
        setActiveTab('help')
        break
      case '/my-account':
        setActiveTab('my-account')
        break
      case '/available-apps':
        setActiveTab('available-apps')
        break
      case '/register/member':
      case '/register/requestor':
      case '/register/contact':
      case '/register/validate':
      case '/register/create':
        setActiveTab('register')
        break
      default:
        setActiveTab('')
        break
    }

    setShowSecondaryNav(
      !location.pathname.includes('/developers') &&
      location.pathname !== '/app-register' &&
      !location.pathname.includes('/app-edit')
    )
  }, [location.pathname])

  const { user, removeUser, status, role } = useUserWithRole()

  const logoutUser = useLogoutUser()
  const handleLogoutUser = () => {
    logoutUser.mutate({ refreshToken: user.refresh_token })
  }

  useEffect(() => {
    if (logoutUser.isSuccess) {
      removeUser()
    }
  }, [logoutUser.isSuccess])

  const getNavLinkClass = (tabName: string) => `nav-link${activeTab === tabName ? ' active-link' : ' inactive-link'}`

  const renderNavItem = (to: string, label: string, className = '') => (
    <li className="nav-item">
      <Link className={`nav-link ${className}`} to={to}>
        {label}
      </Link>
    </li>
  )

  const getHomeText = () => (user && role === 'admin' ? 'Admin Home' : 'Home')

  return (
    <header >
      <Link to="/">
        <div className="client_banner">
          <p>
            <Image
              src={client_logo}
              className="img-fluid"
              alt="Health LX" />
          </p>
        </div>

        <div className="portal_banner">

          <Image
            src={portalBanner}
            className="img-fluid"
            alt="Member Portal"
          />
        </div>

      </Link>
      {showSecondaryNav && status !== 'loading' && (
        <nav id="main_nav" aria-label="main navigation">
          <ul className="nav justify-content-center">
            {renderNavItem(user ? '/' : '/login', getHomeText(), getNavLinkClass('home'))}

            {!user && (
              <>
                {renderNavItem('/help', 'Help', getNavLinkClass('help'))}
                {renderNavItem('/available-apps', 'Available Apps', getNavLinkClass('available-apps'))}
                {!appConfig.use_sso && renderNavItem('/register/requestor', 'Register', getNavLinkClass('register'))}
              </>
            )}

            {user && (
              <>
                {renderNavItem('/my-account', 'My Account', getNavLinkClass('my-account'))}
                {renderNavItem('/available-apps', 'Available Apps', getNavLinkClass('available-apps'))}
                {renderNavItem('/help', 'Help', getNavLinkClass('help'))}
                <li className="nav-item">
                  <Link className="nav-link inactive-link" to="#" onClick={handleLogoutUser}>
                    Log Out
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      )}
    </header>
  )
}

export default Header


