import React, { FC } from 'react'
import URI from '../../DataTypes/URI'

interface ProfileProps {
    profile?: string[];
}

const Profile: FC<ProfileProps> = ({ profile }) => {
    if (profile) {
        return (
            <>
                {profile.map((profileURL, index) => {
                    return (
                        <div key={`Profile-${index}`}>
                            <URI uri={profileURL} />
                        </div>
                    )
                })}
            </>
        )
    }
    return null
}

export default Profile