import React, { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Reaction } from '../../../../types/FHIRTypes/AllergyIntolerance'
import CodeableConcept from '../../DataTypes/CodeableConcept'

interface AdverseReactionProps {
  reactions: Reaction[]
}

const AdverseReaction: FC<AdverseReactionProps> = ({ reactions }) => (
  <>
    {reactions?.map((item: any, index: number) => (
      <div key={index}>
          <Row>
            {/* <Col xs={6}>Substance:</Col>
            <Col xs={6}><CodeableConcept data={item?.substance} dataExtension={item?._substance} /></Col> */}
            {/* <Col xs={6}>Reaction:</Col> */}
            <Col xs={6}><CodeableConcept data={item?.manifestation} dataExtension={item?._manifestation} /></Col>
            {/* <Col xs={6}>Description:</Col>
            <Col xs={6}>{item?.description}</Col>
            <Col xs={6}>Onset:</Col>
            <Col xs={6}>{item?.onset}</Col>
            <Col xs={6}>Severity:</Col>
            <Col xs={6}>{item.severity}</Col>
            <Col xs={6}>Exposure Route:</Col>
            <Col xs={6}><CodeableConcept data={item?.exposureRoute} dataExtension={item?._exposureRoute} /></Col> */}
          </Row>
      </div>
    ))}
  </>
)

export default AdverseReaction