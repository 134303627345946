import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { AdjudicationCodeEnum } from '../../../../../types/AdjudicationCodeEnum'
import humanizeString from 'humanize-string'
import { Card } from 'react-bootstrap'
import { Adjudication } from '../../../../../types/FHIRTypes/ExplanationOfBenefit'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Money from '../../../DataTypes/Money'

interface TotalProps {
    total?: Adjudication[];
}

const EobTotal: FC<TotalProps> = ({ total }) => {
    if (!total || total.length === 0) return null
    const sortLineItems = (lineItems: Adjudication[]) => {
        return lineItems.sort((a, b) => {
            const aCode = a.category?.coding?.[0]?.code
            const bCode = b.category?.coding?.[0]?.code

            return aCode && bCode ?
                aCode > bCode ? 1 : bCode > aCode ? -1 : 0
                : aCode ? 1 : bCode ? -1 : 0
        })
    }

    return (
        <Card className='mt-3'>
            <Card.Header>
                Total
            </Card.Header>
            <Card.Body>
                <Row>
                    {
                        total && sortLineItems(total).map((t: Adjudication, index: number) => (
                            <Col key={`total-item-${index}`} xs={6}>
                                <Row>
                                    <Col>
                                        <CodeableConcept data={t.category} dataExtension={t._category} />
                                    </Col>
                                    <Col className='text-right'>
                                        <Money value={t.amount?.value} />
                                    </Col>
                                </Row>
                            </Col>
                        ))
                    }
                </Row>
            </Card.Body>
        </Card>
    )

}
export default EobTotal