import React, { FC } from 'react'
import { Extension } from '../../../../types/FHIRTypes/Extension'
import { DataAbsentReasonCodeEnum } from '../../../../types/DataAbsentReasonCodeEnum'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'

interface DataAbsentReasonProps {
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const DataAbsentReason: FC<DataAbsentReasonProps> = ({ dataExtension }) => {
  if (!dataExtension) {
    return null
  }

  const extensionsArray = Array.isArray(dataExtension) ? dataExtension : [dataExtension]
  const absentReasons = extensionsArray.map(ext => {
    const dataAbsentExtension = ext.extension?.find(
      (innerExt) => innerExt.url === 'http://hl7.org/fhir/StructureDefinition/data-absent-reason'
    )
    return dataAbsentExtension?.valueCode
      ? DataAbsentReasonCodeEnum[dataAbsentExtension.valueCode as keyof typeof DataAbsentReasonCodeEnum] ||
        dataAbsentExtension.valueCode
      : null
  }).filter(Boolean).join(', ')

  return <span>{absentReasons ? `Data absent reason: ${absentReasons}` : null}</span>
}

export default DataAbsentReason