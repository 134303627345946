import React from 'react'

interface CapitalizedTextProps {
    text?: string
}

const CapitalizedText: React.FC<CapitalizedTextProps> = ({ text }) => {
    if (!text) return null

    const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

    return (
        <span>{capitalizeFirstLetter(text)}</span>
    )
}

export default CapitalizedText