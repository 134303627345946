import React, { FC } from 'react'
import { Period as FhirPeriod } from '../../../../types/FHIRTypes/Period'
import { formatDateWithFormat } from '../../../../utils/helpers'

interface FhirPeriodProps {
    period?: FhirPeriod;
}

const Period: React.FC<FhirPeriodProps> = ({ period }) => {
    const periodStart = period?.start ? formatDateWithFormat(period.start ?? '', 'MM/dd/yyyy') + ';' : ''
    const periodEnd = period?.end ? formatDateWithFormat(period.end ?? '', 'MM/dd/yyyy') + ';' : ''

    const startCaption = period?.start ? 'Start: ' : ''
    const endCaption = period?.end ? 'End: ' : ''


    return (
        <div>{`${startCaption}${periodStart} ${endCaption}${periodEnd}`}</div>
    )
}

export default Period