import React, { FC } from 'react'

interface ResourceTypeProps {
    resourceType?: string;
}

const ResourceType: FC<ResourceTypeProps> = ({ resourceType }) => {
    return <span>{resourceType}</span>


}

export default ResourceType