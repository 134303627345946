import React, { FC } from 'react'
import AllergyList from './AllergyList'
import AllergyDetail from './AllergyDetail'

interface Props {
    allergyData: any;
    patientData: any;
    allergyDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
    handleChangePage: (url: string) => void;
}


const Allergies: FC<Props> = ({ allergyData, patientData, allergyDetailId, page, isFetching, handleChangePage }: Props) => {
    return (
        <div>
            {
                allergyDetailId && page === 'AllergyIntolerance' ? (
                    <AllergyDetail
                        allergyData={allergyData}
                        isFetching={isFetching}
                        allergyDetailId={allergyDetailId} />
                ) : <AllergyList
                    allergyData={allergyData}
                    patientData={patientData}
                    handleChangePage={handleChangePage}
                    isFetching={isFetching}
                />
            }
        </div>
    )
}

export default Allergies