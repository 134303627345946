import React, { FC } from 'react'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface StringProps {
  data?: string | null
  dataExtension?: CodeableConcept | CodeableConcept[] | null
}


const StringDisplay: FC<StringProps> = ({ data, dataExtension }) => {
  if (!data && !dataExtension) {
    return null
  }

  if (dataExtension) {
    return <DataAbsentReason dataExtension={dataExtension} />
  }

  const getStringDisplay = (): string => data ?? ''
  return (
    <div>{getStringDisplay()}</div>
  )
}

export default StringDisplay