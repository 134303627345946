import React, { FC } from 'react'
import { URI as FhirURI } from '../../../../types/FHIRTypes/URI'

interface URLProps {
  uri: FhirURI | undefined
}


const URI:FC<URLProps> = ({uri}) => {
    const getURIDisplay = (): string => {
        
        return uri || ''
      }
    
  return (
    <div>{getURIDisplay()}</div>
  )
}

export default URI