import React from 'react'

interface NarrativeStatusProps {
    status?: string
}

const NarrativeStatus: React.FC<NarrativeStatusProps> = ({ status }) => {
    if (!status) {
        return null
    }

    return (
        <div>Status: {status}</div>
    )
}

export default NarrativeStatus