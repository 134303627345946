import React, { FC, useEffect } from 'react'
import MemberApp from '../../components/Member/App'
import AdminApp from '../../components/Admin/Application'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import isAuthenticated from '../../routes/PrivateRoute'
import useApp from '../../hooks/app/useApp'
import { useParams } from 'react-router-dom'
import useSaveApp from '../../hooks/app/useSaveApp'

interface AppRouteParams {
  appId: string;
}

const App: FC = () => {
  const { user, isLoading } = useUserWithRole()
  const isAdmin = user?.role.find((a: string[]) => a.includes('admin')) ? true : false

  const { appId } = useParams<AppRouteParams>()
  const { data: app, refetch: refetchApp } = useApp(appId)

  const updateApp = useSaveApp()

  useEffect(() => {
    refetchApp()
  }, [])

  return (
    <>
      {
        (isLoading && user === undefined) && (<div style={{ height: 1500 }} />)
      }
      {
        isAdmin ? (
          <AdminApp app={app} updateApp={updateApp} />
        ) : (
          <MemberApp app={app} />
        )
      }
    </>
  )
}

export default isAuthenticated(App)
