import React, { FC, useEffect, useState } from 'react'
import MemberApps from '../../components/Member/Apps'
import AdminApps from '../../components/Admin/Applications'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import isAuthenticated from '../../routes/PrivateRoute'
import useQuery from '../../hooks/location/useQuery'
import useApps from '../../hooks/app/useApps'
import { useHistory } from 'react-router-dom'

const Apps: FC = () => {
  const { isLoading, role } = useUserWithRole()
  const isAdmin = role === 'admin'

  const query = useQuery()
  const [status, setStatus] = useState('')
  const { data: apps, refetch: refetchApps } = useApps(status)

  useEffect(() => {
    if (status) {
      refetchApps()
    }
  }, [status])

  useEffect(() => {
    const statusQuery = query.get('status')
    setStatus(statusQuery || '')
  }, [])

  const history = useHistory()
  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value)
    history.push(`/apps?status=${event.target.value}`)
  }

  return (
    <>
      {
        isLoading && isAdmin === false && (<div style={{ height: 1500 }} />)
      }
      {
        isAdmin ? (
          <AdminApps apps={apps} status={status} changeStatus={handleChangeStatus} />
        ) : (
          <MemberApps apps={apps} />
        )
      }
    </>
  )
}

export default isAuthenticated(Apps)
