import React, { FC } from 'react'
import { Instant as FHIRinstant } from '../../../../types/FHIRTypes/Instant'

interface InstantProps {
    instant?: FHIRinstant
}

const Instant: FC<InstantProps> = ({ instant }) => {
    const getInstantDisplay = (): string => {
        return instant || ''
    }

    return (
        <div>
            {getInstantDisplay()}
        </div>
    )
}

export default Instant