import React, { FC } from 'react'
import { Address as FhirAddress } from '../../../../types/FHIRTypes/Address'
import { getContentOrFallback } from '../../../../utils/fhirUtils/getContentOrFallback'

interface AddressProps {
    address: FhirAddress;
    showUse?: boolean;
}

const Address: FC<AddressProps> = ({ address, showUse }) => {
    const getAddress = () => {
        const fullAddress = address.text || `${address.line?.join(' ') || ''} ${address.city || ''}, ${address.state || ''} ${address.postalCode || ''}`.trim()
        const useType = (showUse && (address.type || address.use)) || ''
        const formattedType = useType.charAt(0).toUpperCase() + useType.slice(1)
        const returnValue = showUse ? `${formattedType}: ${fullAddress} ` : `${fullAddress}`
        return returnValue
    }

    return (
        <div>
            {getContentOrFallback(getAddress())}
        </div>
    )
}

export default Address