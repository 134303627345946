import React, { FC } from 'react'

interface DateTimeOrStringProps {
    dateTime?: string;
    string: string
}

const DateTimeOrString: FC<DateTimeOrStringProps> = ({ dateTime, string }) => {
    if (dateTime) {
        return <span>{dateTime}</span>
    }

    if (string) {
        return <span>{string}</span>
    }
    return null
}

export default DateTimeOrString