import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import { Practitioner } from '../../../../types/FHIRTypes/Practitioner'
import HumanName from '../../DataTypes/HumanName'
import Reference from '../../DataTypes/Reference'
import ReferencePopover from '../../../ReferencePopover'
import { getHumanName } from '../../../../utils/fhirUtils/getHumanName'
import ShowField from '../../DisplayComponents/FieldVisibilityWrapper'
import { FieldConfig } from '../../../../types/FieldConfig'

interface PractitionerReferenceProps {
    practitioner?: FHIRReference
}

const practitionerFieldConfig: FieldConfig = {
    value: { label: 'Value', visibility: 'always' }
}

const PractitionerReference: React.FC<PractitionerReferenceProps> = ({ practitioner }) => {
    if (!practitioner) return null

    const reference = practitioner?.reference?.split('/')
    const practitionerId = reference?.[1] || ''

    const {
        data: practitionerData,
        refetch: getPractitioner,
        isFetching: fetchingPractitioner,
        isLoading: loadingPractitioner
    }: UseQueryResult<Practitioner, Error> = useFhirResourceById(practitionerId, 'Practitioner')

    useEffect(() => {
        if (practitioner) {
            getPractitioner()
        }
    }, [practitioner])

    if (fetchingPractitioner || loadingPractitioner) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    return (
        <div className="d-flex">
            {
                practitionerData?.name
                    ? (
                        <div className="d-flex align-items-center">
                            <HumanName name={practitionerData.name[0]} />
                            <ReferencePopover
                                title={getHumanName(practitionerData.name[0])}
                            >
                                <ShowField field='value' config={practitionerFieldConfig} resource={practitionerData}>
                                    <Reference data={practitioner} />
                                </ShowField>
                            </ReferencePopover>
                        </div>
                    )
                    : <Reference data={practitioner} />
            }
        </div>
    )
}

export default PractitionerReference