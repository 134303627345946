import React, { FC } from 'react'
import { buildPatientBirthday } from '../../../../utils/fhirUtils/patient'

interface DateProps {
    date?: string
}


const Date: FC<DateProps> = ({date}) => {
 const getDateDisplay = (): string => date ?? ''
  return (
    <div>{getDateDisplay()}</div>
  )
}

export default Date