import React, { FC, useEffect } from 'react'
import { Button, Container, Jumbotron } from 'react-bootstrap'
import { useHistory } from 'react-router'
import useLogoutUser from '../../hooks/authentication/useLogoutUser'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import isAuthenticated from '../../routes/PrivateRoute'

const AccessDenied: FC = () => {

  const { user, removeUser } = useUserWithRole()

  const logoutUser = useLogoutUser()
  const history = useHistory()
  const handleLogoutUser = () => {
    logoutUser.mutate({ refreshToken: user.refresh_token })
  }

  useEffect(() => {
    if (logoutUser.isSuccess) {
      removeUser()
      history.push('/login')
    }
  }, [logoutUser.isSuccess])

  return (
    <Container
      style={{
        paddingTop: 10,
        paddingBottom: 10
      }}
    >
      <Jumbotron fluid>
        <Container>
          <h2>Access Denied</h2>
          <p>
            It looks like the member associated with this account has recently turned 18 and as the owner you have lost access to view their data.
          </p>
          <p>
            <Button variant="primary" className='custom' onClick={handleLogoutUser}>Go Back</Button>
          </p>
        </Container>
      </Jumbotron>
    </Container>
  )
}

export default isAuthenticated(AccessDenied)
