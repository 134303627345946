import React, { FC } from 'react'
import { Quantity as FhirQuantity } from '../../../../types/FHIRTypes/Quantity'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'

interface FhirQuantityProps {
    data: FhirQuantity | undefined
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Quantity: React.FC<FhirQuantityProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) {
        return null
    }
    
    if (dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }
    
    return (
        <span>
            {data?.value}{data?.unit ? ` ${data.unit}` : ''}
        </span>
    )
}

export default Quantity