import React from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import DataAbsentReason from '../DataAbsentReason'
import Extension from '../Extension'
import { ref } from 'yup'

interface ReferenceProps {
    data?: FHIRReference | CodeableConcept
    dataExtension?: CodeableConcept | CodeableConcept[] | null
}

const Reference: React.FC<ReferenceProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) return null

    if (dataExtension) {
        return <DataAbsentReason dataExtension={dataExtension} />
    }

    if (data?.extension) {
        return <Extension nestedExtension={data as CodeableConcept | CodeableConcept[] | undefined} />
    }

    const displayValue = (ref: FHIRReference) => ref.display || ref.reference || ref.identifier?.value || ''

    if (displayValue.length > 0 && Array.isArray(data)) {
        return <span>{data.map(displayValue).join(', ')}</span>
    } else {
        return <span>{displayValue(data as FHIRReference)}</span>
    }
}

export default Reference