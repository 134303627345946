import React, { FC, useState } from 'react'
import { Row, Col, Spinner, OverlayTrigger, Popover } from 'react-bootstrap'
import { Provenance } from '../../../../types/FHIRTypes/Provenance'
import Agent from '../../DisplayComponents/Agent'
import '../../../../App.scss'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { useEffect } from 'react'
import Reference from '../../DataTypes/Reference'
import Instant from '../../DataTypes/Instant'
import PeriodOrDateTime from '../../DataTypes/PeriodOrDateTime'

interface Props {
    resourceName: string;
    resourceId: string | undefined;
}

const ProvenanceDetail: FC<Props> = ({ resourceName, resourceId }) => {
    const [popoverVisible, setPopoverVisible] = useState<{ [key: number]: boolean }>({})
    const provenanceSearchParams = { target: `${resourceName}/${resourceId}` }
    const { data: provenanceData, refetch: getProvenance, isFetching: fetchingProvenance, isLoading: loadingProvenance } = useFhirResourceById('', 'Provenance', provenanceSearchParams)
    const provenanceEntries: Provenance[] = provenanceData?.entry?.map((entry: any) => entry.resource) || []

    useEffect(() => {
        if (resourceId) {
            getProvenance()
        }
    }, [resourceId])

    const validProvenanceEntries = provenanceEntries.filter(entry => entry !== undefined)

    if (validProvenanceEntries.length === 0) {
        return null
    }

    const handleToggle = (index: number, show: boolean) => {
        setPopoverVisible(prev => ({ ...prev, [index]: show }))
    }

    return (
        <div className='footer'>
            {(fetchingProvenance || loadingProvenance) ? (
                <div>
                    <Spinner as="span" animation="border" role="status" aria-hidden="true" />
                    <span style={{ marginLeft: '10px' }}>Loading Provenance Data...</span>
                </div>
            ) : (
                <div>
                    <hr />
                    <h6>FHIR Resource Provenance</h6>
                    <Row>
                        <Col sm={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {validProvenanceEntries.map((provenance, index) => (
                                <span key={index} style={{ whiteSpace: 'nowrap' }}>
                                    <OverlayTrigger
                                        trigger={['hover', 'focus', 'click']}
                                        placement="auto"
                                        show={popoverVisible[index]}
                                        onToggle={(show) => handleToggle(index, show)}
                                        overlay={
                                            <Popover
                                                id={`popover-positioned-right-${index}`}
                                                style={{ maxWidth: '600px', width: '95%' }}
                                                onMouseEnter={() => handleToggle(index, true)}
                                                onMouseLeave={() => handleToggle(index, false)}
                                            >
                                                <Popover.Title as="h3" className='font-weight-bold'>Entry {index + 1}</Popover.Title>
                                                <Popover.Content>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <dt>Target Resource</dt>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <dd>
                                                                    {provenance?.target.map((target, targetIndex) => <Reference key={`target-${targetIndex}`} data={target} />)}
                                                                </dd>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <dt>Provenance Occurance</dt>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <dd>{<PeriodOrDateTime period={provenance?.occurredPeriod} dateTime={provenance?.occurredDateTime} />}</dd>
                                                            </Col>

                                                            <Col sm={12}>
                                                                <dt>Provenance Recorded</dt>
                                                            </Col>
                                                            <Col sm={12}>
                                                                <dd><Instant instant={provenance?.recorded} /></dd>
                                                            </Col>

                                                        <Col sm={12}>
                                                            <dt>Agent</dt>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <dd>
                                                                {<Agent agents={provenance?.agent} />}
                                                            </dd>
                                                        </Col>
                                                    </Row>
                                                </Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <a href="#" className="provenance-link" onClick={(e) => e.preventDefault()}>Entry {index + 1}</a>
                                    </OverlayTrigger>
                                    {index < validProvenanceEntries.length - 1 && <span> | </span>}
                                </span>
                            ))}
                        </Col>
                    </Row>
                </div>
            )}
        </div >
    )
}

export default ProvenanceDetail